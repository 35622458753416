(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name home.user.controller:ResetPasswordCtrl
   *
   * @description
   *
   */
  angular
  .module('neo.authentication')
  .controller('NewPasswordCtrl', NewPasswordCtrl);

  function NewPasswordCtrl($filter, $state, $stateParams, $mdToast, NewPassword) {
    var vm = this;

    if ($stateParams.token === null || angular.isUndefined($stateParams.token)) {
      $state.go('authentication.login');
    }

    vm.ctrlName = 'NewPasswordCtrl';
    vm.cardTitle = 'CREATE_NEW_PASSWORD';

    vm.sendPassword = function () {
      vm.newUser = {newPassword: vm.pwd, resetToken: $stateParams.token};
      NewPassword.update(vm.newUser,
        function () {
          $state.go('authentication.login');
          $mdToast.show(
            $mdToast.simple()
            .textContent($filter('translate')('SUCCESSFUL_PASSWORD_CHANGE'))
            .position('bottom left')
            .hideDelay(3000)
          );
        },
        function () {
          $mdToast.show(
            $mdToast.simple()
            .textContent($filter('translate')('TOAST_ERROR'))
            .position('bottom left')
            .hideDelay(3000)
          );
        });
    };
  }
}());
